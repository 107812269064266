/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "GTM-TG36F9G",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Hitesh",
  logo_name: "hitesh.p()",
  nickname: "hitesh",
  full_name: "Hitesh Paliya",
  subTitle:
    "Security Analyst. DevSecOps. AWS Enthusiast. Bug Bounty Hunter. Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/1nm-5KulxURB1dDT8err7AXdtjCa2ORVI/view?usp=sharing",
  mail: "mailto:hpaliyah@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/HiteshPaliya",
  linkedin: "https://www.linkedin.com/in/hitesh-paliya/",
  gmail: "hpaliyah@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "https://twitter.com/hitesh_paliya",
  instagram: " ",
};

const skills = {
  data: [
    {
      title: "Cyber Security",
      fileName: "FullStackImg",
      skills: [
        "⚡ Testing WebApps, Mobile Apps, APIs, Internal Networks, Cloud infra",
        "⚡ Conducted penetration tests from insider's threat perspective",
        "⚡ Actively managing and running organization's Bug Bounty Program",
      ],
      softwareSkills: [
        {
          skillName: "Hackerone",
          fontAwesomeClassname: "simple-icons:hackerone",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "BugCrowd",
          fontAwesomeClassname: "simple-icons:bugcrowd",
          style: {
            color: "#F26822",
          },
        },
        {
          skillName: "Nmap",
          fontAwesomeClassname: "file-icons:nmap",
          style: {
            backgroundColor: "transparent",
            color: "#029FCE",
          },
        },
        {
          skillName: "Bash",
          fontAwesomeClassname: "simple-icons:gnubash",
          style: {
            color: "#4EAA25",
          },
        },
        {
          skillName: "Nessus",
          fontAwesomeClassname: "file-icons:nessus",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Kali Linux",
          fontAwesomeClassname: "simple-icons:kalilinux",
          style: {
            backgroundColor: "transparent",
            color: "#557C94",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Wireshark",
          fontAwesomeClassname: "simple-icons:wireshark",
          style: {
            backgroundColor: "transparent",
            color: "#4F7AF8",
          },
        },
        {
          skillName: "Zendesk",
          fontAwesomeClassname: "simple-icons:zendesk",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "JIRA",
          fontAwesomeClassname: "simple-icons:jirasoftware",
          style: {
            color: "#0052CC",
          },
        },
        {
          skillName: "Virtual Box",
          fontAwesomeClassname: "simple-icons:virtualbox",
          style: {
            color: "#81C459",
          },
        },
        {
          skillName: "VMWare",
          fontAwesomeClassname: "simple-icons:vmware",
          style: {
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#FF6C37",
          },
        },
        {
          skillName: "Curl",
          fontAwesomeClassname: "simple-icons:curl",
          style: {
            color: "#FF0000",
          },
        },
        {
          skillName: "HackTheBox",
          fontAwesomeClassname: "simple-icons:hackthebox",
          style: {
            color: "#9FEF00",
          },
        },
        {
          skillName: "TryHackMe",
          fontAwesomeClassname: "simple-icons:tryhackme",
          style: {
            color: "#A100FF",
          },
        },
        {
          skillName: "Powershell",
          fontAwesomeClassname: "simple-icons:powershell",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Ngrok",
          fontAwesomeClassname: "simple-icons:ngrok",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "DevSecOps",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Creating CICD pipelines",
        "⚡ Task Automation using custom scripts",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience working on multiple cloud platforms",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "simple-icons:grafana",
          style: {
            color: "#F46800",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Nginx",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "simple-icons:ansible",
          style: {
            color: "#EE0000",
          },
        },
        {
          skillName: "Gitbook",
          fontAwesomeClassname: "simple-icons:gitbook",
          style: {
            color: "#3884FF",
          },
        },
        {
          skillName: "Strapi",
          fontAwesomeClassname: "simple-icons:strapi",
          style: {
            color: "#2F2E8B",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#DC382D",
          },
        },
        {
          skillName: "Notion",
          fontAwesomeClassname: "simple-icons:notion",
          style: {
          },
        },
        {
          skillName: "DynamoDB",
          fontAwesomeClassname: "simple-icons:amazondynamodb",
          style: {
            color: "#4053D6",
          },
        },
        {
          skillName: "Tomcat",
          fontAwesomeClassname: "simple-icons:apachetomcat",
          style: {
            color: "#F8DC75",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Joplin",
          fontAwesomeClassname: "simple-icons:joplin",
          style: {
            color: "#1071D3",
          },
        },
        {
          skillName: "Let's Encrypt",
          fontAwesomeClassname: "simple-icons:letsencrypt",
          style: {
            color: "#003A70",
          },
        },
      ],
    },
    {
      title: "Code",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Getting hands dirty with Bash and Python",
        "⚡ Wrote wrapper Scripts to automate security testing",
        "⚡ Created Auto Deployment Scripts to publish code quickly",
      ],
      softwareSkills: [
        {
          skillName: "Shell Scripting",
          fontAwesomeClassname: "vscode-icons:file-type-shell",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "BootStrap",
          fontAwesomeClassname: "logos-bootstrap",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "JSON",
          fontAwesomeClassname: "mdi:code-json",
          style: {
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "vscode-icons:file-type-sql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Markdown",
          fontAwesomeClassname: "simple-icons:markdown",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Vim",
          fontAwesomeClassname: "simple-icons:vim",
          style: {
            backgroundColor: "transparent",
            color: "#019733",
          },
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "VSCode",
          fontAwesomeClassname: "simple-icons:visualstudiocode",
          style: {
            backgroundColor: "transparent",
            color: "#007ACC",
          },
        },
        {
          skillName: "Eclipse",
          fontAwesomeClassname: "simple-icons:eclipseide",
          style: {
            backgroundColor: "transparent",
            color: "#2C2255",
          },
        },
        {
          skillName: "Javascript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "transparent",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            backgroundColor: "transparent",
            color: "#9625cf",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            backgroundColor: "transparent",
            color: "#A8B9CC",
          },
        },
        {
          skillName: "Google Analytics",
          fontAwesomeClassname: "simple-icons:googleanalytics",
          style: {
            backgroundColor: "transparent",
            color: "#E37400",
          },
        },


      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Silver Oak College Of Engineering and Technology",
      subtitle: "B.E. in Information Technology Engineering",
      logo_path: "silveroak.png",
      alt_name: "SOCET",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Web Application, Network, Cloud security etc.",
        "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
      ],
      website_link: "https://silveroakuni.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Linux Server Management and Security",
      subtitle: "coursera.org | University of Michigan",
      logo_path: "uofcs.png",
      certificate_link:
        "https://coursera.org/share/e336377bcf3cc86b3f5c0ecf8b41c5e6",
      alt_name: "coursera.org | University of Colorado",
      color_code: "#F7F7F7",
    },
    {
      title: "Practical Ethical Hacking",
      subtitle: "udemy.com",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-5de5352f-637f-4888-93a3-70992e1a49b0/",
      alt_name: "udemy.com",
      color_code: "#F6B808",
    },
    {
      title: "Programming for Everybody",
      subtitle: "coursera.org | University of Michigan",
      logo_path: "uofm.png",
      certificate_link:
        "https://coursera.org/share/e336377bcf3cc86b3f5c0ecf8b41c5e6",
      alt_name: "coursera.org",
      color_code: "#2AAFED",
    },
    {
      title: "Python Data Structures",
      subtitle: "coursera.org | University of Michigan",
      logo_path: "uofm.png",
      certificate_link:
        "https://coursera.org/share/0d6af3b35c4c79d9e4e570c3ad3f06d2",
      alt_name: "coursera.org",
      color_code: "#E2405F",
    },
    {
      title: "Certified Cyber Security Expert v4.0",
      subtitle: "TechDefence Labs",
      logo_path: "techd.png",
      certificate_link: " ",
      alt_name: "CCSE",
      color_code: "#8C151599",
    },
    {
      title: "Learn Command Line",
      subtitle: "Codecademy",
      logo_path: "codecademy.jpg",
      certificate_link:
        "https://www.codecademy.com/profiles/HiteshPaliya/certificates/c87ba0541f8be78bc2f4ba1128233f6f",
      alt_name: "codecademy",
      color_code: "#F2F2F2",
    },
    {
      title: "Certified Network Security Specialist",
      subtitle: "ICSI",
      logo_path: "icsi.jpg",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#ffffff",
    },
    {
      title: "Using Python to Access Web Data",
      subtitle: "coursera.org | University of Michigan",
      logo_path: "uofm.png",
      certificate_link:
        "https://coursera.org/share/95206c810a49e0fa58853baa6252af9c",
      alt_name: "coursera.org",
      color_code: "#7A7A7A",
    },
    {
      title: "C++ Course",
      subtitle: "Solo Learn",
      logo_path: "sololearn.png",
      certificate_link: "https://www.sololearn.com/Certificate/1051-10468876/jpg/",
      alt_name: "sololearn",
      color_code: "#C5E2EE",
    },
    {
      title: "Introduction to Cloud",
      subtitle: "CognitiveClass.ai | IBM",
      logo_path: "ibm.jpg",
      certificate_link: "https://courses.cognitiveclass.ai/certificates/e94e7abcdfe14592b4c05df27c082bee",
      alt_name: "cognitiveclass.ai",
      color_code: "#ffc475",
    },
    {
      title: "Network Security Expert 1",
      subtitle: "Fortinet",
      logo_path: "fortinet.jpg",
      certificate_link: " ",
      alt_name: "Fortinet",
      color_code: "#db291d",
    },
    {
      title: "Learn Python 3",
      subtitle: "Codecademy",
      logo_path: "codecademy.jpg",
      certificate_link: "https://www.codecademy.com/profiles/HiteshPaliya/certificates/6c152bd262967f8c941c9707ed636bda",
      alt_name: "codecademy",
      color_code: "#F2F2F2",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Internship",
  description:
    "I've completed one internship as a Security Analyst. Did few projects to automate security testing in finding and fixing security issues quickly. Aspire to become an AppSec Engineer. Can perform Network, Web, API, as well as Mobile Application assessment. Currently working as a DevSecOps Engineer and working with AWS infrastructure. Looking forward to hone my skills further everyday | Open for opportunities.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Security Analyst | DevSecOps",
          company: "Quicko",
          company_url: "https://quicko.com/",
          logo_path: "quicko.jpg",
          duration: "August 2021",
          location: "Ahmedabad",
          description:
            "Quicko is an online tax planning, preparation & filing platform for individuals & businesses.",
          color: "#2962FF",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Security Analyst",
          company: "TechDefence Labs",
          company_url: "https://techdefencelabs.com/",
          logo_path: "techd.png",
          duration: "Februrary 2020 - November 2020",
          location: "Ahmedabad",
          description:
            "I did security assessments of various clients including Banking, Corporate, e-Commerce as well as Payment sectors. Researched and analyzed known hacker methodologies, system exploits and vulnerabilities along with their patches to assist Red Team Assessment activities",
          color: "#ee3c26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Development might not be my forte, however I am always learning new things everyday and therefore have tried to create few projects which actually made sense and helped me out in my infosec journey as well.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "hitesh.jpg",
    description:
      "You can reach out to me at the following social media handles. I will try to revert back as fast as I can.",
  },
  blogSection: {
    title: "Leisure Activities",
    subtitle:
      "Apart from the usual, you can find me hanging out doing following things:",
    link: " ",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Automated Recon System",
      url: " ",
      description: "A wrapper script to invoke number of security assessment tools to automate vulnerability testing of web based applications.",
      languages: [
        {
          name: "Bash",
          iconifyClass: "simple-icons:gnubash",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "1",
      name: "Pentest+",
      url: " ",
      description:
        "A web based application portal to provide a GUI to user for easy security assessment of web based applications without needing to do any setup on their own.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Bash",
          iconifyClass: "simple-icons:gnubash",
        },
        {
          name: "BootStrap",
          iconifyClass: "logos-bootstrap",
        },
        {
          name: "Javascript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Django",
          iconifyClass: "simple-icons:django",
        },
      ],
    },

  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
